@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer components {
  .btn-loader:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-loader:disabled {
    position: relative;
    color: var(--primary-color) !important;
  }
  .btn-loader-red:disabled::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid;
    border-top-color: white;
    -webkit-animation: loader 0.8s linear infinite;
    animation: loader 0.8s linear infinite;
  }

  .btn-loader-red:disabled {
    position: relative;
    color: #f04444 !important;
  }
}

:root {
  --primary-color: #0059ac;
  --light-primary-color: #f3f7ff;
  --light-gray: #a7a7a7;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  /* display: none; */
}

.no-hide::-webkit-scrollbar {
  display: block;
}

.table *::-webkit-scrollbar {
  display: none;
}

html {
  font-size: 62.5%;
}

@media (max-width: 768px) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: poppins;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--primary-color);
}

input:disabled,
textarea:disabled,
select:disabled,
button:disabled {
  cursor: not-allowed;
}

.ps-menu-button:hover {
  /* background-color:#64748B !important; */
  color: #64748B !important;
}

.ps-menu-button:hover .ps-menu-icon {
  color: #64748B !important;
}
.modal {
  margin-top: 50px;
  height: 800px;
}

.app-modal-content {
  border-radius: 10px;
  background: #fff;
  border: none;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.6rem;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 30px;
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header h4.modal-title {
  font-size: 1.6rem;
  color: var(--primary-color);
  /* margin-bottom: 20px; */
}

.modal-header .close {
  padding: 10px;
  background-color: transparent;
  border: none;
  font-size: 2.4rem;
}

.rec-modal-body {
  padding: 30px;
}

.modal-xlg {
  width: 1200px !important;
  max-width: 1200px;
}

.modal-lg {
  width: 900px !important;
  max-width: 900px;
}
.modal-md {
  width: 600px !important;
  max-width: 600px;
}

.modal-sm {
  width: 500px !important;
  max-width: 500px;
}
.modal-normal {
  width: 750px !important;
  max-width: 900px;
}

.modal-xsm {
  width: 400px !important;
  max-width: 400px;
}

.modal-xsm .modal-title {
  font-size: 0.5rem;
}

.modal-subtitle {
  margin-bottom: 30px;
  margin-top: -20px;
  font-size: 14px;
  font-family: inherit;
  color: #5e6c85;
}

.modal-dialog {
  max-width: 90vw;
  max-height: 100%;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: visible;
  overflow-y: visible;
}

.table-responsive > div:first-of-type {
  /* overflow-x: scroll; */
  overflow-y: visible;
}

.rdt_TableHeadRow .rdt_TableCol {
  color: #3a3a3a;
  text-align: center;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .rdt_TableCell { */
/* font-weight: 500 !important; */
/* } */

.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.4rem !important;
}

.stdropdown-container {
  border: 1px solid #e9e9e9 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.stsearch-box input {
  border: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}

.address-box {
  box-shadow: 1px 1px 1rem #00000025;
}

.expandable-row .rdt_TableHeadRow .rdt_TableCol {
  font-size: 1.3rem;
}

.profile-avatar {
  background-size: cover !important ;
}
.profile-avatar:hover{
  background-color: var(--light-gray) !important;
  transition: ease-in-out 1s;
}

.profile-avatar--select {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  height: 130px;
  width: 130px;
  border-radius: 100%;
  display: flex;
  border: 2px solid transparent;
  align-items: center;
  justify-content: center;
}

.popup-content {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 3px 33px 5px rgb(22 49 96 / 19%);
  margin: 10px;
}

.popup-option {
  padding: 8px 15px;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 5px;
  align-items: center;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm, div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  font-size: 1.4rem !important;
}
div:where(.swal2-container) .swal2-html-container {
  font-size: 1.6rem !important;
}
div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 2rem !important;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  width: 40rem !important;
}

.progress {
	position: relative;
	padding: 0 0 0 5rem;
	list-style: none;
  font-family: inherit;
}
.progress__item {
	position: relative;
	min-height: 75px;
	margin-bottom: 10px;
}
.progress__item:after {
	content: "";
	position: absolute;
	top: 0.5rem;
	left: -4.5rem;
	width: 28px;
	aspect-ratio: 1;
	border-radius: 5px;
	border: 2px solid var(--primary-color);
	font-weight: 400;
	font-size: 13px;
	line-height: 2rem;
	text-align: center;
	background: #fff;
	color: #392a60;
}
.progress__item:last-child:before {
	border: none !important;
}
.progress__item.progress__item--completed:before {
	content: "";
	position: absolute;
	left: -3.25rem;
	top: 33px;
	height: 70%;
	width: 1px;
	border-left: 1px solid var(--primary-color);
	border-style: dashed;
}
.progress__item.progress__item--completed:after {
	content: "\2713";
	font-weight: 400;
	background: #fff;
	color: #fff;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
.progress__title {
	padding: 0.4rem 0 0.5rem;
	margin: 0;
	font-size: 1.6rem;
	font-weight: 500;
	color: #47505b;
}
.progress__info {
	counter-increment: list;
	font-size: 1.3rem;
	color: #5e6c85;
	font-weight: 400;
	
}
