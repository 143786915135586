.bgImage {
  background-image: url("src/assets/images/banner.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: var(--primary-color);
}
.otpContainer {
  align-items: center;
  gap: 25px;
  margin-block-end: 10px;
}
.otpInput {
  border-radius: 5px;
  border: 0.966px solid #aaa3a3;
  width: 45px !important;
  height: 45px;
}
.otpLabel {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: var(--light-gray);
  width: 100%;
}

@media screen and (max-width: 1224px) {
  .bgImage {
    background-size: 450px;
    background-position: bottom center;
  }
}
